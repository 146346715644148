






























































































































































@import '@design';
@import '@styleMixins';

.treps-section--title.tagline {
  @extend .freight-pro--font;

  text-transform: uppercase;
}

.home-merch-section,
.home-view-menus-section {
  .img-card {
    height: 100%;
    border-radius: 0;
  }
}
.home-view-menus-section {
  .left-side,
  .img-card {
    min-height: 75vh;
  }
}

.treps-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }

  @media (max-width: 650px) {
    padding: 3px;
  }
}
